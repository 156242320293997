import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React from 'react';
import { Provider } from 'react-redux';

import { NotificationToastProvider } from '@/components/layout/notification-toast/NotificationToastProvider';
import store from './configureStore';

const queryClient = new QueryClient();

// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element }: any) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <NotificationToastProvider />
        {element}
      </Provider>
    </QueryClientProvider>
  );
};
