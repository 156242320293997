import { BookmarkAction } from '@/types/bookmark';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import internalAPI from '../../../services/internalAPI';
import signOutAction from '../../actions/signOutAction';
import { RootState } from '../../configureStore';
import { Bookmark } from './bookmarkSlice';

export type AddBookmark = {
  entityUuid: string;
  state?: BookmarkAction['options'];
};

export const addBookmark = createAsyncThunk<
  // Return type of the payload creator
  Bookmark,
  // First argument to the payload creator
  AddBookmark,
  {
    rejectValue: { error: string };
  }
>('bookmark/add', async ({ entityUuid, state = {} }, { getState, rejectWithValue, dispatch }) => {
  try {
    const { bookmarkInfo } = getState() as RootState;
    const response = await internalAPI(true).post(`/api/bookmark/${entityUuid}`, {
      state: state,
      requestMethod: bookmarkInfo.bookmarks[entityUuid] ? 'PATCH' : 'POST'
    });
    if (response.status == 200 && response.data) {
      return response.data;
    }
    if (response.status == 409 && response.data) {
      return rejectWithValue({ error: 'Bookmark added already' });
    }
    console.error(`Unable to add bookmark. Status: ${response.status}`);
    return rejectWithValue({ error: 'Unable to add bookmark' });
  } catch (e) {
    const err = e as AxiosError;
    if (err.response) {
      if (err.response.status == 401) {
        dispatch(signOutAction());
      }
    }
    return rejectWithValue({ error: 'Unable to add bookmark' });
  }
});
