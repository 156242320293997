import { LAST_ACTION } from './types';

/**
 * Stores user last known action to aide with redirecting
 * after logging in or registering.
 *
 * @param {any} location
 * @param {any} action
 * @param {any} actionData
 *
 * @return {any}
 */
const updateLastAction = (location, action, actionData) => async (dispatch) => {
  const pathsWeDontRedirectTo = ['/', '/login', '/register', '/forgot-password'];
  let path = location.pathname;
  pathsWeDontRedirectTo.forEach((dontUpdate) => {
    if (path == dontUpdate) {
      path = null;
      return;
    }
  });

  if (location.search.indexOf('oauth') >= 0) {
    path = null;
    return;
  }

  if (path) {
    if (location.hash != undefined) {
      path += location.hash;
    }
    if (location.search != undefined) {
      path += location.search;
    }

    sessionStorage.setItem(
      'lastAction',
      JSON.stringify({
        lastPage: path,
        action: action,
        actionData: actionData
      })
    );

    return dispatch({
      type: LAST_ACTION,
      payload: { lastPage: path, action: action, actionData: actionData }
    });
  } else {
    return false;
  }
};

export default updateLastAction;
