import { NotificationToast, NotificationToastState } from '@/types/notification-toast';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const initialState: NotificationToastState = {
  notificationToasts: []
};

export const notificationToastSlice = createSlice({
  name: 'notificationToasts',
  initialState,
  reducers: {
    addNotificationToast: (state, action: PayloadAction<NotificationToast>) => {
      state.notificationToasts.push(action.payload);
    },
    removeNotificationToast: (state, action: PayloadAction<string>) => {
      state.notificationToasts = state.notificationToasts.filter(
        (notificationToast) => notificationToast.id !== action.payload
      );
    }
  }
});

export const { addNotificationToast, removeNotificationToast } = notificationToastSlice.actions;
export default notificationToastSlice.reducer;
