import { cn } from '@/lib/helpers/utils';
import { Link } from 'gatsby';
import React from 'react';
import { ImageType } from '../../../types/cards';
import { ImageTypes } from '../../../types/content';
export type LogoStyleType = 'dark' | 'light' | null;

export const getLogoPath = (siteName: string, file: string) => {
  return `${siteName}/logos/${file}`;
};

/**
 * Returns the site logo depending on the sitename, for the site header
 * @param data {any}
 * @param classes {string}
 * @param small {boolean}
 * @param mode {LogoStyleType}
 * @param isSignedIn {boolean}
 * @returns {JXS}
 */
export const siteLogoLink = (
  data: any,
  linkClasses: string,
  logoClasses: string,
  small: boolean = false,
  mode: LogoStyleType = null,
  isSignedIn: boolean = false
) => {
  const homepageLink = isSignedIn ? '/dashboard' : '/';
  return (
    <Link
      to={homepageLink}
      className={`${linkClasses} focus:outline-none md:focus-visible:ring-2 md:focus-visible:ring-primary-400 focus-visible:ring-offset-2 focus-visible:rounded-sm`}
      data-cy={`image:view-home`}
    >
      {siteLogo(data.site.siteMetadata.brandName, logoClasses, small, mode)}
    </Link>
  );
};

/**
 * Returns the site logo depending on the sitename, and flags that are passed in.
 * @param siteName {string}
 * @param classes {string}
 * @param small {boolean}
 * @param mode {LogoStyleType}
 * @returns {JSX}
 */
export const siteLogo = (siteName: string, classes: string, small: boolean = false, mode: LogoStyleType = null) => {
  return (
    <svg
      role="img"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox={`0 0 ${small ? '32' : '151.7'} 32`}
      className={cn('site-logo', classes ? classes : '', mode ? mode : '')}
    >
      <title>{siteName}</title>
      <g id="logos">
        <g>
          {siteName === 'gradireland' && (
            <g id="gradireland-horizontal-cmyk" className="logo">
              <g id="gradireland-icon" className={`icon`}>
                <path
                  className="st1"
                  d="M28.1,0H3.8C1.7,0,0,1.7,0,3.8v8.9h10.9l-2-1.9c-0.7-0.7-1.5-1.7-1.5-2.7c-0.1-1.8,1.4-3.4,3.2-3.4h0.1
                  c1.1,0.1,2.2,0.7,2.8,1.6L22,16l-8.5,9.6c-0.8,0.9-1.7,1.8-3,1.8c-1.8,0-3.3-1.4-3.3-3.2v-0.1c0-1.1,0.5-2.1,1.3-2.9l2.3-1.9H0
                  v8.9C0,30.3,1.7,32,3.8,32h24.4c2.1,0,3.8-1.7,3.8-3.8V3.8C31.9,1.7,30.2,0,28.1,0z"
                />
              </g>
              {!small && (
                <g id="gradireland-type" className={`type`}>
                  <g>
                    <path
                      className="st2"
                      d="M49.1,10.9h2.7v8.8c0,1.7-0.5,3-1.6,3.9S47.6,25,45.9,25c-2.2,0-3.8-0.7-5-2v-0.2l1.4-1.7h0.2
                    c0.4,0.5,0.9,0.8,1.5,1.1s1.3,0.4,1.9,0.4c0.8,0,1.5-0.2,2-0.6s0.8-1,0.8-1.7v-0.6c-0.9,0.6-1.9,1-3,1c-1.4,0-2.7-0.5-3.6-1.4
                    s-1.4-2.1-1.4-3.5s0.5-2.6,1.4-3.6s2.2-1.4,3.6-1.4c1.3,0,2.4,0.4,3.4,1.2V10.9L49.1,10.9z M44.6,17.5c0.5,0.5,1.1,0.7,1.8,0.7
                    s1.3-0.2,1.8-0.7c0.5-0.5,0.7-1.1,0.7-1.8c0-0.8-0.2-1.4-0.7-1.8c-0.5-0.5-1.1-0.7-1.8-0.7s-1.3,0.2-1.8,0.7
                    c-0.5,0.5-0.7,1.1-0.7,1.8C43.9,16.4,44.1,17,44.6,17.5z"
                    />
                    <path
                      className="st2"
                      d="M61.8,11l0.2,0.3l-0.7,2.3l-0.2,0.1c-0.3-0.2-0.8-0.3-1.3-0.2c-0.6,0-1.2,0.2-1.5,0.7
                    c-0.4,0.4-0.6,1-0.6,1.7v5.7h-3.3V10.9h2.8v1.4c0.8-1.1,1.7-1.6,2.9-1.6C60.7,10.6,61.3,10.7,61.8,11L61.8,11z"
                    />
                    <path
                      className="st2"
                      d="M67.3,10.6c1.5,0,2.7,0.4,3.6,1.1c0.9,0.7,1.4,1.9,1.4,3.4v6.4h-2.7v-1c-0.9,0.8-2.1,1.2-3.3,1.2
                    s-2.2-0.3-3-0.9c-0.8-0.6-1.2-1.4-1.2-2.4c0-1.1,0.4-2,1.2-2.6c0.8-0.7,1.9-1,3.3-1c0.8,0,1.7,0.2,2.6,0.5v-0.5
                    c0-0.5-0.2-0.9-0.7-1.3s-1-0.5-1.8-0.5c-1,0-1.8,0.3-2.6,0.9H64L63,12.2V12c0.5-0.4,1.2-0.8,2-1S66.6,10.6,67.3,10.6L67.3,10.6
                    z M67.1,19.7c0.6,0,1.2-0.2,1.6-0.5c0.4-0.4,0.7-0.8,0.7-1.3v-0.6c-0.8-0.4-1.5-0.5-2.2-0.5c-0.6,0-1.1,0.1-1.4,0.4
                    c-0.4,0.3-0.5,0.6-0.5,1.1s0.2,0.8,0.5,1S66.5,19.7,67.1,19.7L67.1,19.7z"
                    />
                    <path
                      className="st2"
                      d="M85.4,7l0.3,0.2v14.3h-2.8v-1.2c-0.9,1-2.1,1.4-3.4,1.5c-1.5,0-2.8-0.5-3.8-1.6c-0.2-0.2-0.4-0.4-0.5-0.6
                    c-0.7-0.9-1-2-1-3.3c0-1.6,0.5-2.9,1.5-4s2.3-1.6,3.8-1.6c1.1,0,2,0.3,2.9,0.9V7.3L85.4,7L85.4,7z M80,18.9
                    c0.7,0,1.3-0.2,1.8-0.8c0.5-0.5,0.7-1.2,0.7-2c0-0.8-0.2-1.5-0.7-2c-0.5-0.5-1.1-0.8-1.8-0.8s-1.4,0.3-1.9,0.8s-0.7,1.2-0.7,2
                    c0,0.8,0.2,1.5,0.7,2C78.6,18.7,79.3,18.9,80,18.9L80,18.9z"
                    />
                  </g>
                  <g>
                    <path
                      className="st2"
                      d="M91,8.7C90.8,8.9,90.5,9,90.3,9s-0.5-0.1-0.7-0.3S89.3,8.3,89.3,8c0-0.3,0.1-0.5,0.3-0.7
                    C89.8,7.1,90,7,90.3,7s0.5,0.1,0.7,0.3s0.3,0.4,0.3,0.7C91.3,8.3,91.2,8.5,91,8.7z M89.8,21.5V10.9h1v10.6H89.8z"
                    />
                    <path
                      className="st2"
                      d="M99.8,10.9L99.8,10.9l-0.3,0.9l-0.1,0c-0.3-0.2-0.6-0.3-1.1-0.2c-0.8,0-1.5,0.4-2,1.1s-0.8,1.6-0.8,2.7
                    v6.1h-1V10.9h1v1.8c0.7-1.4,1.7-2.1,3-2.1C98.9,10.6,99.4,10.7,99.8,10.9z"
                    />
                    <path
                      className="st2"
                      d="M110.6,16.5h-9.3c0.1,1.3,0.5,2.3,1.4,3.1c0.8,0.8,1.8,1.2,3,1.2c0.7,0,1.5-0.2,2.2-0.5s1.3-0.8,1.7-1.4
                    h0.1l0.6,0.5v0.1c-0.5,0.7-1.2,1.2-2,1.6c-0.8,0.4-1.7,0.6-2.6,0.6c-1.5,0-2.8-0.5-3.9-1.5c-1.1-1-1.6-2.4-1.6-4s0.5-2.9,1.5-4
                    s2.2-1.6,3.8-1.6c1.7,0,2.9,0.6,3.9,1.9c0.8,1,1.2,2.2,1.2,3.6C110.6,16.2,110.6,16.3,110.6,16.5L110.6,16.5z M105.5,11.6
                    c-1.2,0-2.1,0.4-2.9,1.1c-0.8,0.7-1.2,1.7-1.3,2.9h8.2c-0.1-0.9-0.4-1.7-0.8-2.3C108,12.1,106.9,11.6,105.5,11.6z"
                    />
                    <path className="st2" d="M113.3,21.5V7.2l0.9-0.1l0.1,0.1v14.4H113.3L113.3,21.5z" />
                    <path
                      className="st2"
                      d="M121.6,10.6c1.3,0,2.4,0.4,3.2,1.1c0.8,0.7,1.1,1.7,1.1,3v6.7h-1v-1.6c-0.4,0.6-1,1.1-1.7,1.4
                    c-0.7,0.3-1.4,0.5-2.2,0.5c-1.2,0-2.2-0.3-2.9-0.9c-0.8-0.6-1.1-1.4-1.1-2.4s0.4-1.9,1.2-2.5c0.8-0.6,1.9-0.9,3.2-0.9
                    c1.2,0,2.4,0.3,3.6,0.9v-1.3c0-0.9-0.3-1.6-0.9-2.2s-1.4-0.8-2.4-0.8c-1.3,0-2.5,0.5-3.5,1.4H118l-0.5-0.7v-0.1
                    c0.5-0.5,1.1-0.9,1.9-1.2S120.9,10.6,121.6,10.6L121.6,10.6z M121.1,20.8c1,0,1.9-0.3,2.7-0.9c0.8-0.6,1.1-1.3,1.1-2.3v-0.9
                    c-1.2-0.6-2.3-1-3.6-1c-1,0-1.8,0.2-2.4,0.7s-0.9,1.1-0.9,1.9c0,0.7,0.3,1.3,0.8,1.7C119.4,20.6,120.2,20.8,121.1,20.8
                    L121.1,20.8z"
                    />
                    <path
                      className="st2"
                      d="M134.1,10.6c1.3,0,2.3,0.4,3.1,1.2s1.2,1.8,1.2,3.1v6.6h-1.1V15c0-1-0.3-1.9-0.9-2.5s-1.4-0.9-2.4-0.9
                    s-1.9,0.3-2.6,1c-0.7,0.7-1,1.5-1,2.5v6.4h-1.1V10.9h1v1.7C131.1,11.3,132.4,10.6,134.1,10.6L134.1,10.6z"
                    />
                    <path
                      className="st2"
                      d="M151.6,7l0.1,0.1v14.4h-1.1v-2c-0.5,0.7-1.1,1.2-1.8,1.6s-1.6,0.6-2.5,0.6c-1.6,0-2.8-0.5-3.8-1.6
                    s-1.5-2.4-1.5-4s0.5-2.9,1.5-4s2.3-1.6,3.8-1.6c1.9,0,3.3,0.7,4.3,2.2V7.1L151.6,7L151.6,7z M146.4,20.8c1.2,0,2.3-0.4,3.1-1.3
                    s1.2-2,1.2-3.3s-0.4-2.5-1.2-3.3c-0.8-0.8-1.9-1.2-3.1-1.2s-2.3,0.4-3.1,1.3s-1.2,2-1.2,3.3s0.4,2.4,1.2,3.3
                    C144.1,20.3,145.1,20.8,146.4,20.8L146.4,20.8z"
                    />
                  </g>
                </g>
              )}
            </g>
          )}
          {siteName === 'targetjobs' && (
            <g id="targetjobs-horizontal-cmyk" className="logo">
              <g id="targetjobs-icon" className={`icon`}>
                <path
                  className="st3"
                  d="M21.8,11.6c-5.6,0-10.2,4.5-10.2,10.2S16.1,32,21.8,32c1.4,0,2.7-0.3,4-0.8c3.7-1.5,6.2-5.1,6.2-9.4
                  C32,16.2,27.4,11.6,21.8,11.6C21.8,11.6,21.8,11.6,21.8,11.6z M21.8,26.9c-2.8,0-5.1-2.3-5.1-5.1s2.3-5.1,5.1-5.1
                  s5.1,2.3,5.1,5.1l0,0C26.9,24.6,24.6,26.9,21.8,26.9z"
                />
                <path
                  className="st3"
                  d="M5.9,21.8c0-9,7.1-16.4,15.9-16.4c3.7,0,7.4,1.4,10.2,3.8V3.8C32,1.7,30.3,0,28.2,0H3.8C1.7,0,0,1.7,0,3.8
                  v24.5c0,2,1.7,3.7,3.8,3.7h5.6C7.2,29.1,5.9,25.5,5.9,21.8z"
                />
              </g>
              {!small && (
                <g id="targetjobs-type" className={`type`}>
                  <g>
                    <path
                      className="st2"
                      d="M48.8,18.6l0.8,1.9l-0.1,0.2c-0.7,0.5-1.6,0.8-2.5,0.8c-1.1,0-2-0.3-2.7-1c-0.7-0.6-1.1-1.6-1.1-2.8v-4.3
                    h-1.8v-2.3h1.8v-3l2.9-0.3L46.4,8v3.2h3v2.3h-3v4c0,1,0.4,1.4,1.2,1.4c0.4,0,0.7-0.1,1-0.4L48.8,18.6L48.8,18.6z"
                    />
                    <path
                      className="st2"
                      d="M55.3,10.9c1.4,0,2.5,0.4,3.4,1c0.9,0.7,1.3,1.8,1.3,3.2v6.1h-2.5v-1c-0.9,0.8-2,1.2-3.2,1.2
                    s-2.2-0.3-2.9-0.9c-0.7-0.6-1.1-1.4-1.1-2.3c0-1,0.4-1.9,1.2-2.5c0.8-0.6,1.8-0.9,3.2-0.9c0.8,0,1.6,0.2,2.5,0.5v-0.5
                    c0-0.5-0.2-0.9-0.7-1.2s-1-0.5-1.7-0.5c-0.9,0-1.8,0.3-2.5,0.8h-0.2l-1-1.6v-0.2c0.5-0.4,1.2-0.7,2-1S54.6,10.9,55.3,10.9
                    L55.3,10.9z M55.1,19.5c0.6,0,1.1-0.2,1.5-0.5s0.6-0.8,0.6-1.2v-0.5c-0.7-0.3-1.4-0.5-2.1-0.5c-0.6,0-1,0.1-1.4,0.4
                    c-0.3,0.3-0.5,0.6-0.5,1s0.2,0.8,0.5,1S54.6,19.6,55.1,19.5L55.1,19.5z"
                    />
                    <path
                      className="st2"
                      d="M69.7,11.3l0.1,0.2l-0.6,2.2l-0.2,0.1c-0.3-0.2-0.7-0.2-1.2-0.2c-0.6,0-1.1,0.2-1.5,0.6
                    c-0.4,0.4-0.6,1-0.6,1.6v5.4h-3.1V11.2h2.6v1.3c0.7-1,1.7-1.6,2.8-1.6C68.5,10.9,69.1,11.1,69.7,11.3L69.7,11.3z"
                    />
                    <path
                      className="st2"
                      d="M78.1,11.2h2.5v8.4c0,1.6-0.5,2.9-1.6,3.8s-2.4,1.3-4,1.3c-2.1,0-3.7-0.6-4.8-1.9v-0.2l1.3-1.6h0.2
                    c0.4,0.4,0.8,0.8,1.4,1c0.6,0.3,1.2,0.4,1.8,0.4c0.8,0,1.4-0.2,1.9-0.5s0.8-0.9,0.8-1.6v-0.6c-0.8,0.6-1.8,0.9-2.9,0.9
                    c-1.4,0-2.5-0.5-3.4-1.4c-0.9-0.9-1.4-2-1.4-3.4s0.5-2.5,1.4-3.4c0.9-0.9,2.1-1.4,3.4-1.4c1.2,0,2.3,0.4,3.2,1.2V11.2
                    L78.1,11.2z M73.8,17.4c0.4,0.5,1,0.7,1.7,0.7s1.3-0.2,1.7-0.7c0.4-0.5,0.7-1,0.7-1.7c0-0.7-0.2-1.3-0.7-1.8s-1-0.7-1.7-0.7
                    s-1.3,0.2-1.7,0.7c-0.4,0.5-0.7,1.1-0.7,1.8C73.2,16.4,73.4,17,73.8,17.4z"
                    />
                    <path
                      className="st2"
                      d="M93.1,16.9h-7.5c0.1,0.8,0.4,1.3,0.9,1.7c0.5,0.4,1.1,0.6,1.8,0.6c1.2,0,2.1-0.4,2.8-1.1h0.2l1.4,1.3v0.2
                    c-0.5,0.6-1.2,1.1-2,1.4c-0.8,0.3-1.6,0.5-2.5,0.5c-1.6,0-2.9-0.5-4-1.5c-1.1-1-1.6-2.3-1.6-3.8c0-1.5,0.5-2.8,1.5-3.8
                    c1-1,2.3-1.5,3.9-1.5c1.5,0,2.7,0.5,3.7,1.4c1,1,1.5,2.2,1.5,3.8C93.2,16.4,93.1,16.7,93.1,16.9z M87.9,13.1
                    c-0.6,0-1.1,0.2-1.5,0.5c-0.4,0.4-0.7,0.8-0.7,1.5h4.5c-0.1-0.6-0.3-1-0.6-1.4C89.1,13.3,88.5,13.1,87.9,13.1z"
                    />
                    <path
                      className="st2"
                      d="M101.2,18.6l0.8,1.9l-0.1,0.2c-0.7,0.5-1.6,0.8-2.6,0.8c-1.1,0-2-0.3-2.7-1C96,20,95.6,19,95.6,17.8v-4.3
                    h-1.8v-2.3h1.8v-3l2.9-0.3L98.7,8v3.2h3v2.3h-3v4c0,1,0.4,1.4,1.2,1.4c0.4,0,0.7-0.1,1-0.4L101.2,18.6L101.2,18.6z"
                    />
                  </g>
                  <g>
                    <path
                      className="st2"
                      d="M105.9,10.9h1v10.5c0,0.9-0.2,1.6-0.5,2.2c-0.3,0.5-0.8,1-1.4,1.4h-0.1l-0.4-0.8v-0.1
                    c0.5-0.3,0.8-0.6,1.1-1c0.2-0.4,0.3-0.9,0.3-1.6C105.9,21.6,105.9,10.9,105.9,10.9z M107.1,8.7c-0.2,0.2-0.4,0.3-0.7,0.3
                    s-0.5-0.1-0.7-0.3s-0.3-0.4-0.3-0.7c0-0.3,0.1-0.5,0.3-0.7s0.4-0.3,0.7-0.3s0.5,0.1,0.7,0.3s0.3,0.4,0.3,0.7
                    C107.3,8.3,107.3,8.5,107.1,8.7z"
                    />
                    <path
                      className="st2"
                      d="M118.7,20.2c-1,1.1-2.3,1.6-3.8,1.6s-2.8-0.5-3.8-1.6s-1.5-2.4-1.5-4s0.5-2.9,1.5-4s2.3-1.6,3.8-1.6
                    s2.8,0.5,3.8,1.6s1.5,2.4,1.5,4S119.8,19.1,118.7,20.2z M111.8,19.5c0.8,0.9,1.9,1.3,3.1,1.3s2.3-0.4,3.1-1.3s1.2-2,1.2-3.3
                    s-0.4-2.4-1.2-3.3s-1.8-1.3-3.1-1.3s-2.3,0.4-3.1,1.3s-1.2,2-1.2,3.3S111,18.7,111.8,19.5z"
                    />
                    <path
                      className="st2"
                      d="M127.9,10.6c1.5,0,2.7,0.5,3.7,1.6s1.4,2.4,1.4,4s-0.5,2.9-1.4,4s-2.2,1.6-3.7,1.6c-1.8,0-3.2-0.8-4.1-2.3
                    v2h-1V7.2l0.9-0.1l0.1,0.1v5.8C124.8,11.4,126.2,10.6,127.9,10.6L127.9,10.6z M127.9,20.9c1.2,0,2.2-0.4,3-1.3s1.2-2,1.2-3.3
                    s-0.4-2.4-1.2-3.3s-1.8-1.3-3-1.3s-2.2,0.4-3,1.3c-0.8,0.8-1.2,2-1.2,3.4s0.4,2.5,1.2,3.4C125.7,20.5,126.7,20.9,127.9,20.9
                    L127.9,20.9z"
                    />
                    <path
                      className="st2"
                      d="M138.2,21.8c-1.6,0-2.8-0.7-3.7-2v-0.1l0.6-0.5h0.1c0.3,0.5,0.7,0.9,1.2,1.2c0.5,0.3,1.1,0.4,1.7,0.4
                    c0.8,0,1.4-0.2,1.8-0.5s0.7-0.8,0.7-1.4c0-0.3-0.1-0.5-0.2-0.8s-0.2-0.4-0.4-0.6s-0.3-0.3-0.6-0.5c-0.3-0.2-0.5-0.3-0.7-0.4
                    s-0.4-0.2-0.8-0.4c-0.1-0.1-0.2-0.1-0.3-0.1c-0.4-0.2-0.7-0.3-0.9-0.5s-0.5-0.3-0.8-0.5c-0.3-0.2-0.5-0.5-0.6-0.8
                    s-0.2-0.6-0.2-1c0-0.8,0.3-1.5,0.9-2s1.4-0.8,2.3-0.8c0.7,0,1.3,0.2,1.8,0.5c0.6,0.3,1,0.7,1.3,1.3v0.1l-0.6,0.5h-0.1
                    c-0.6-0.9-1.4-1.4-2.5-1.4c-0.6,0-1.1,0.2-1.5,0.5s-0.6,0.7-0.6,1.2c0,0.5,0.2,0.9,0.5,1.1c0.3,0.3,0.8,0.6,1.5,0.9
                    c1.2,0.5,2,0.9,2.4,1.2c0.8,0.6,1.2,1.4,1.2,2.4c0,0.9-0.3,1.6-1,2.1S139.3,21.8,138.2,21.8L138.2,21.8z"
                    />
                  </g>
                </g>
              )}
            </g>
          )}
        </g>
      </g>
    </svg>
  );
};

interface OrganisationPlaceHolderLogoProps extends React.HTMLAttributes<HTMLDivElement> {
  organisationName: string;
}

export const OrganisationPlaceHolderLogo = ({ organisationName, className }: OrganisationPlaceHolderLogoProps) => {
  const firstLetter = organisationName ? organisationName.substring(0, 1) : '';
  return (
    <div
      className={cn(
        '@container bg-deep-200 flex flex-grow text-gray-700 font-semibold leading-8 uppercase justify-center items-center',
        className
      )}
    >
      <span className="text-2xl @[80px]:text-4xl">{firstLetter}</span>
    </div>
  );
};

/**
 * Return placeholder image for Organisation logo on a card.
 * @param {any} config
 * @param {string} organisationName
 * @return {ImageType}
 */
export const getOrganisationPlaceholderLogoImageType = (config: any, organisationName?: string): ImageType => {
  !config && (config = {});
  !config.images && (config.images = {});
  !config.images.logo &&
    (config.images.logo = {
      classes: 'flex flex-col min-h-[3.5rem] -m-1 rounded-sm overflow-hidden'
    });
  return {
    type: ImageTypes.html,
    image: <OrganisationPlaceHolderLogo organisationName={organisationName as string} />,
    alt: organisationName ? `${organisationName} logo` : ''
  };
};

/**
 * Return placeholder image for Organisation cohort logo on a card.
 * @param {any} config
 * @param {string} organisationName
 * @return {ImageType}
 */
export const getOrganisationPlaceholderCohortLogoImageType = (config: any, organisationName?: string): ImageType => {
  !config && (config = {});
  !config.images && (config.images = {});
  !config.images.cohortLogo &&
    (config.images.cohortLogo = {
      classes: 'flex flex-col min-w-[3rem] min-h-[3rem] -m-1 rounded-sm overflow-hidden'
    });
  return {
    type: ImageTypes.html,
    image: <OrganisationPlaceHolderLogo organisationName={organisationName as string} />,
    alt: organisationName ? `${organisationName} logo` : ''
  };
};

export const getContributorPlaceholderLogo = (firstName?: string, lastName?: string, className?: string) => {
  const firstLetter = firstName ? firstName.substring(0, 1) : '';
  const lastLetter = lastName ? lastName.substring(0, 1) : '';

  return (
    <div
      className={`rounded-full bg-turquoise border-2 border-white shadow-sm text-white leading-8 uppercase flex justify-center items-center  ${className}`}
    >
      <span>
        {firstLetter}
        {lastLetter}
      </span>
    </div>
  );
};
