import { siteLogo } from '@/components/layout/utils/Logo';
import { Button } from '@/components/ui/Button';
import { useMediaQuery } from '@/lib/hooks';
import { Opportunity } from '@/types/content';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import updateLastAction from '../../../app/redux/actions/updateLastAction';
import { ActionType } from '../../../app/services/authTypes';

interface AttributionNotificationToastProps {
  id: string;
  opportunity: Opportunity;
  applyUrl: string;
  updateAction: (location: any, action: any, actionData?: any) => any;
}

const AttributionNotificationToast = ({
  id,
  opportunity,
  applyUrl,
  updateAction
}: AttributionNotificationToastProps) => {
  const data = useStaticQuery(graphql`
    query {
      ...Site
    }
  `);

  const isDesktop = useMediaQuery();
  const brandName = data.site.siteMetadata.brandName;

  React.useEffect(() => {
    updateAction({ pathname: location.pathname, search: '' }, ActionType.VIEW);
  }, []);

  return (
    <div className="grid grid-cols-[1fr_100px] grid-rows-2 gap-x-2 md:grid-cols-[min-content_1fr_124px] md:gap-x-3">
      <div className="hidden md:block md:row-start-1 md:row-end-3 md:col-start-1 md:col-end-2 md:content-center">
        {siteLogo(brandName, 'size-10', true)}
      </div>
      <div className="text-base leading-6 font-semibold row-span-1 col-start-1 col-end-2 md:col-start-2 md:col-end-3 md:text-lg">
        Didn’t redirect? Click the apply button to try again.
      </div>
      <div className="text-sm text-gray-400 row-span-1 col-start-1 col-end-2 md:col-start-2 md:col-end-3 md:text-base">
        Good luck and don’t forget to mention <span className="font-semibold">{brandName}</span> in your application!
      </div>
      <div className="row-start-1 row-end-3 col-start-2 col-end-3 content-end md:col-start-3 md:col-end-4">
        <Button
          asChild
          appearance="background"
          size={!isDesktop ? 'sm' : 'md'}
          className="w-full"
          onClick={() => toast.dismiss(id)}
        >
          <a href={applyUrl} target="_blank" rel="nofollow">
            <span>Apply</span>
            <ArrowTopRightOnSquareIcon className="w-3 md:w-4" />
          </a>
        </Button>
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  updateAction: updateLastAction
};

export default React.memo(connect(null, mapDispatchToProps)(AttributionNotificationToast));
